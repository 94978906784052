import React, { useState } from "react";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { stripePk } from "../../env";
import { PaymentContainer } from "../../styles/Checkout/main";
import PaymentForm from "./PaymentForm";

const PaymentSide = (props) => {
  const [stripePromise] = useState(() => loadStripe(stripePk));
  const options = {};

  return (
    <Elements stripe={stripePromise} options={options}>
      <PaymentContainer>
        <PaymentForm />
      </PaymentContainer>
    </Elements>
  );
};

export default PaymentSide;
