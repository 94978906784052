import React, { useState, useRef } from "react";
import PropTypes from "prop-types";
import "./index.scss";

const InputCode = ({
  length,
  splitStep,
  loading,
  onComplete,
  containerStyles,
  inputStyles,
}) => {
  const [code, setCode] = useState([...Array(length)].map(() => ""));
  const inputs = useRef([]);

  const processInput = (e, slot) => {
    const num = e.target.value;
    if (/[^0-9]/.test(num)) return;
    const newCode = [...code];
    newCode[slot] = num;
    setCode(newCode);
    if (slot !== length - 1) {
      inputs.current[slot + 1].focus();
    }
    if (newCode.every((num) => num !== "")) {
      onComplete(newCode.join(""));
    }
  };

  const onKeyUp = (e, slot) => {
    if (e.keyCode === 8 && !code[slot] && slot !== 0) {
      const newCode = [...code];
      newCode[slot - 1] = "";
      setCode(newCode);
      inputs.current[slot - 1].focus();
    }
  };

  return (
    <div className='code-container' style={containerStyles}>
      {code.map((num, idx) => {
        const hasDivider = (idx + 1) % splitStep === 0 && idx < length - 1;
        return (
          <React.Fragment key={idx}>
            <input
              key={`num${idx}`}
              type='text'
              inputMode='numeric'
              maxLength={1}
              value={num}
              autoFocus={!code[0].length && idx === 0}
              readOnly={loading}
              onChange={(e) => processInput(e, idx)}
              onKeyUp={(e) => onKeyUp(e, idx)}
              ref={(ref) => inputs.current.push(ref)}
              className='code-container__number'
              style={inputStyles}
            />
            {hasDivider && (
              <span
                className='code-container__divider'
                key={`divider${idx}`}
              ></span>
            )}
          </React.Fragment>
        );
      })}
    </div>
  );
};
InputCode.propTypes = {
  length: PropTypes.number.isRequired,
  splitStep: PropTypes.number.isRequired,
  loading: PropTypes.bool,
  onComplete: PropTypes.func.isRequired,
  containerStyles: PropTypes.objectOf(PropTypes.any),
  inputStyles: PropTypes.objectOf(PropTypes.any),
};
InputCode.defaultProps = {
  loading: false,
  containerStyles: undefined,
  inputStyles: undefined,
};
export default InputCode;
