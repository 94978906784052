export const ArrowRightIcon = (props) => {
  const svgStyles = {
    width: props.width,
    height: props.height,
    fill: props.fill || "currentColor",
  };
  return (
    <svg
      width='12'
      height='12'
      viewBox='0 0 12 12'
      style={svgStyles}
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M6 -2.62268e-07L4.9425 1.0575L9.1275 5.25L-2.29485e-07 5.25L-2.95052e-07 6.75L9.1275 6.75L4.935 10.935L6 12L12 6L6 -2.62268e-07Z'
        fill={svgStyles.fill}
      />
    </svg>
  );
};
