import React, { useContext, useEffect, useState, useRef } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import axios from "axios";
import { toast } from "react-toastify";
import { REACT_APP_API_ZEALINNOVATIONS } from "../env";
import CurrentSystemCtx from "../context/CurrentSystemCtx";
import {
  LogoContainer,
  ActiveLogo,
  OptionsContainer,
  PlanDisplayPricing,
  SubmitBtn,
  FormGroup100,
  CheckoutLabel,
  CheckoutInput,
} from "../styles/Checkout/main";
import InputCode from "../components/InputCode";
import { errorCode } from "../constants";
import { regexPattern } from "../constants";

const FORM_TYPE = {
  CODE_VERIFICATION: 0,
  CODE_ERROR: 1,
  CODE_EXPIRED: 2,
  PASSWORD_SET: 3,
};
const Verification = (props) => {
  const navigator = useNavigate();
  const [searchParams] = useSearchParams();
  const { currentSystem } = useContext(CurrentSystemCtx);
  const theme = currentSystem.theme;
  
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [code, setCode] = useState();
  const [formType, setFormType] = useState(FORM_TYPE.CODE_VERIFICATION);
  const password = useRef();
  const confirmPassword = useRef();
  const verification_id = searchParams.get("verification_id");

  useEffect(() => {
    if (!verification_id) {
      navigator(-1);
    }
  }, []); // eslint-disable-line

  const _submitCode = async (e) => {
    try {
      e.preventDefault();
      setIsSubmitting(true);
      const res = await axios.post(
        `${REACT_APP_API_ZEALINNOVATIONS}/account/verify_account`,
        {
          verification_id,
          code, // code in the state
        }
      );
      if (res?.data?.success) {
        setFormType(FORM_TYPE.PASSWORD_SET);
        return;
      }
    } catch (err) {
      console.log(err);
      const message = err?.response?.data?.message?.message || err;
      if (message?.name === errorCode.CODE_EXPIRED) {
        setFormType(FORM_TYPE.CODE_EXPIRED);
        toast.error(message?.message);
        return;
      }
      setFormType(FORM_TYPE.CODE_ERROR);
      if (message?.name === errorCode.CODE_WRONG) {
        toast.error("Code is incorrect.");
        return;
      }
      toast.error(message?.message);
    } finally {
      setIsSubmitting(false);
    }
  };

  const _resendCode = async () => {
    try {
      setIsSubmitting(true);
      setFormType(FORM_TYPE.CODE_VERIFICATION);
      const res = await axios.post(
        `${REACT_APP_API_ZEALINNOVATIONS}/account/resend_verification_code`,
        { verification_id }
      );
      if (res?.data?.success) {
        toast.success("Code has been resent!");
      }
    } catch (err) {
      console.log(err);
      if (err?.response?.data?.message?.message) {
        toast.error(err?.response?.data?.message?.message);
      }
    } finally {
      setIsSubmitting(false);
    }
  };

  const _submitPassword = async (e) => {
    e.preventDefault();
    const pass = password.current.value;
    const confirmPass = confirmPassword.current.value;
    try {
      if (pass !== confirmPass) {
        toast.error("Password does not match!");
        return;
      }
      if (!regexPattern.USER_PASS.regex.test(pass)) {
        toast.error("Password is invalid!");
        return;
      }
      setIsSubmitting(true);
      const res = await axios.post(
        `${REACT_APP_API_ZEALINNOVATIONS}/account/set_password`,
        {
          verification_id,
          password: pass,
        }
      );
      if (res?.data?.success) {
        navigator("/allset");
      }
    } catch (err) {
      console.log(err);
      const message = err?.response?.data?.message?.message || err;
      if (message) {
        toast.error(message?.message);
      }
    } finally {
      setIsSubmitting(false);
    }
  };

  const _completeInputCode = (code) => {
    setCode(code);
    setFormType(FORM_TYPE.CODE_VERIFICATION);
    document.getElementById("verify-code-btn").focus();
  };

  const _renderForm = () => {
    if (formType === FORM_TYPE.CODE_EXPIRED) {
      return (
        <div>
          <h2>Verification code expired</h2>
          <p style={{ maxWidth: 506 }}>
            Click below to send a new verification code to the email on file.
          </p>
          <SubmitBtn
            type='button'
            className='mt-5 px-3'
            disabled={isSubmitting}
            onClick={_resendCode}
          >
            Send new code
          </SubmitBtn>
        </div>
      );
    }
    if (formType === FORM_TYPE.PASSWORD_SET) {
      return (
        <form
          id='set-password'
          className='text-left no-select'
          autoComplete='off'
          onSubmit={_submitPassword}
        >
          <h2 className='text-center mb-0'>Account Verified</h2>
          <p className='text-center mb-4'>
            Please enter and confirm your password for your account.
            <CheckoutLabel
              className='mt-3'
              style={{ fontWeight: "initial", fontSize: "small" }}
            >
              {regexPattern.USER_PASS.message}
            </CheckoutLabel>
          </p>
          <FormGroup100>
            <CheckoutLabel
              htmlFor='password'
              className='form-label text-nowrap'
            >
              Password
            </CheckoutLabel>
            <CheckoutInput
              type='password'
              className='form-control'
              id='password'
              placeholder=''
              ref={password}
              disabled={isSubmitting}
            />
          </FormGroup100>
          <FormGroup100>
            <CheckoutLabel
              htmlFor='confirm-pass'
              className='form-label text-nowrap'
            >
              Confirm Password
            </CheckoutLabel>
            <CheckoutInput
              type='password'
              className='form-control'
              id='confirm-pass'
              placeholder=''
              ref={confirmPassword}
              disabled={isSubmitting}
            />
          </FormGroup100>
          <FormGroup100
            className='text-center pt-3 pb-4'
            style={{ position: "relative" }}
          >
            <SubmitBtn
              form='set-password'
              id='set-password-btn'
              type='submit'
              disabled={isSubmitting}
            >
              {isSubmitting ? (
                <div className='spinner-border' role='status'></div>
              ) : (
                "Submit"
              )}
            </SubmitBtn>
            {isSubmitting && (
              <small
                style={{
                  position: "absolute",
                  left: 0,
                  right: 0,
                  bottom: -11,
                  opacity: 0.7,
                }}
              >
                Please wait! It may take a moment to setup your account.
              </small>
            )}
          </FormGroup100>
        </form>
      );
    }
    const isCodeError = formType === FORM_TYPE.CODE_ERROR;
    return (
      <form id='verify-code' autoComplete='off' onSubmit={_submitCode}>
        <h2>Please enter your verification code</h2>
        <p>
          You should have received an email containing your registration
          verification code.
        </p>
        <InputCode
          length={6}
          splitStep={3}
          loading={isSubmitting}
          onComplete={_completeInputCode}
          containerStyles={{
            marginTop: 60,
            marginBottom: 60,
            borderColor: isCodeError ? theme.danger : theme.label,
          }}
          inputStyles={{
            borderColor: isCodeError ? theme.danger : theme.label,
          }}
        />
        <SubmitBtn
          type='submit'
          form='verify-code'
          id='verify-code-btn'
          disabled={isSubmitting}
        >
          {isSubmitting ? (
            <div className='spinner-border' role='status'></div>
          ) : (
            "Submit"
          )}
        </SubmitBtn>
        <p className='pt-3 pb-3'>
          Didn't receive the verification code?{" "}
          {isSubmitting ? (
            <b>Resending ...</b>
          ) : (
            <a href={`${window.location.href}#`} onClick={_resendCode}>
              <b>Resend</b>
            </a>
          )}
        </p>
      </form>
    );
  };

  if (!verification_id) {
    return null;
  }

  return (
    <OptionsContainer style={{ borderRadius: 6, minHeight: 660 }}>
      <LogoContainer className='small pt-5'>
        <ActiveLogo src={currentSystem.logo} alt={currentSystem.name} />
        {currentSystem.powered && (
          <>
              {/* powered by&nbsp;
            <PrimaryLogoContainer>
              <PrimaryLogo />
            </PrimaryLogoContainer> */}
          </>
        )}
      </LogoContainer>
      <PlanDisplayPricing
        className='text-center m-auto pt-0'
        style={{
          fontWeight: 500,
          paddingLeft: 40,
          paddingRight: 40,
          minHeight: 408,
          maxWidth: 506,
          display: "flex",
          flexFlow: "column",
          justifyContent: "space-between",
        }}
      >
        {_renderForm()}
        <div>
          <small className='small'>
            &copy; Zeal Stream, 2024 &amp; beyond. All Rights Reserved.
          </small>
        </div>
      </PlanDisplayPricing>
    </OptionsContainer>
  );
};

export default Verification;
