import styled from "styled-components";
import { Label,Input, Button, Img, Div, H5 } from "../../helpers/elements";

const PrimaryLogoContainer = styled(Div)`height:21px; display:inline-block; vertical-align:middle; color:${props=>props.theme.secondary};`
const ActiveLogo = styled(Img)`height:42px; display:block; margin:0 auto 21px; fill:${props=>props.theme.secondary};`
const Divider = styled.hr`color: ${props=>props.theme.secondary}; border: 1px solid ${props=>props.theme.secondary};	width:100%;	dsplay:block;	height:100px;`



const CheckoutContainer = styled(Div)`background:linear-gradient(180deg, ${props=>props.theme.primary}, ${props=>props.theme.secondary});height:100%; display:flex; justify-content:center; align-items:center; @media(max-width:991px){	display:block;}`
const CheckoutWrapper = styled(Div)`
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.25);
  background: ${(props) => props.theme.secondary};
  color: ${(props) => props.theme.contrastText};
  display: flex;
  justify-content: flex-start;
  align-items: stretch;
  flex-flow: row;
  max-width: 1050px;
  max-height: 95vh;
  border-radius: 6px;
  @media (max-width: 991px) {
    display: block;
    max-height: initial;
    height: 100%;
    border-radius: 0;
  }
`;
const OptionsContainer = styled(Div)`
  border-radius: 6px 0 0 6px;
  background: #fff;
  color: ${(props) => props.theme.secondary};
  flex: 1 0 42%;
  max-height: inherit;
  overflow: hidden auto;
  height: inherit;
  @media (max-width: 991px) {
    border-radius: 0 !important;
  }
`;
const PaymentContainer = styled(Div)`
  border-radius: 6px 0 0 6px;
  flex: 1 0 57%;
  label {
    color: ${(props) => props.theme.contrastText}80;
  }
  input {
    color: ${(props) => props.theme.contrastText};
    -webkit-text-fill-color: ${(props) => props.theme.contrastText};
    border-color: ${(props) => props.theme.contrastText};
  }
`;
const PaymentWrapper = styled(Div)`padding:0;`
const Foot = styled(Div)`padding:40px;text-align:center;font-weight:500;`
const FormGroup50 = styled(Div)`display:flex; flex-flow:row wrap; justify-content:space-between; align-items:flex-start; margin-bottom:40px; gap:20px;`
const FormGroup75 = styled(Div)`display:flex; flex-flow:row no-wrap; justify-content:space-between; align-items:stretch; margin-bottom:20px;gap:20px;font-size:.9rem;color:#fff;flex:1 auto;
@media(max-width:646px){
	display:block;
	text-align:center;
}
`
const FormGroup100 = styled(Div)`margin-bottom:40px;`
const QuantityContainer = styled(Div)`border:thin solid #fff; border-radius:4px; padding:20px 14px; color:#fff; white-space: nowrap;font-size:1.2rem;text-align:center; flex:1 25%;`
const QuantitySelector = styled(Div)`border:thin solid #fff; border-radius:40px; width:24px; height:24px; background-color:${props=>props.theme.primary}; align-items:center;display:flex;justify-content:center;font-size:.9rem;cursor:pointer;`
const QuantitySelectorContainer = styled(Div)`align-items:center;display:flex;justify-content:center;flex:row wrap; gap:16px; color:#fff; margin-top:10px;`

const SelectedPlanContainer = styled(Div)`align-items:stretch;display:flex;justify-content:space-between;flex:row wrap; gap:40px; color:#fff;
@media(max-width:646px){
	display:block;
} 
`
const SelectedPlanItem = styled(Div)`
  background-color: ${(props) => props.theme.primary};
  padding: 20px 20px 8px 20px;
  border-radius: 4px;
  filter: drop-shadow(0px 4px 8px rgba(0, 0, 0, 0.2));
  flex: 1 50%;
  min-width: 200px;
  text-align: center;
  display: flex;
  flex-flow: column wrap;
  align-items: center;
  gap: 8px;
  .small:first-child,
  .current-price {
    font-weight: 500;
  }
  @media (max-width: 646px) {
    margin-bottom: 20px;
    min-width: initial;
  }
`;

const LogoContainer = styled(Div)`padding:40px 20px 18px 20px; text-align:center;color:${props=>props.theme.secondary};fill:${props=>props.theme.secondary};font-weight: normal;`
const PlansDisplayContainer = styled(Div)`
  width: 367px;
  margin: auto;
  padding-bottom: 20px;
  @media (max-width: 991px) {
    width: auto;
    margin: auto 20px;
  }
`;
const PlanDisplay = styled(Div)`
	border:1px solid ${(props) =>
    props.theme.light}; border-radius:4px; margin-bottom:1.2rem;
`;
const PlanDisplayHeader = styled(Div)`
  background: ${(props) => props.isselected === "true" ? props.theme.secondary : props.theme.primary};
  color: #fff;
	margin: -1px;
  border-radius: 4px 4px 0 0;
  font-size: .875rem;
  font-weight: 600;
  display: flex;
  align-items: center;
  justify-content: space-between;
  label {
    display: flex;
    align-items: center;
		margin: 11px 20px;
  }
  input {
    margin: 0 12px 0 3px;
    width: 17px;
    height: 17px;
  }
	a {
		padding: 10px 20px;
	}
`;
const PlanDisplayPricing = styled(Div)`
  color: ${(props) => props.theme.secondary};
  padding: 20px;
  .name {
    font-size: 1.125rem;
    font-weight: 700;
  }
  .price {
    font-weight: 600;
    font-size: 1.5rem;
    margin: 15px 0 18px 0;
  }
  .recurrence {
    font-size: 1.063rem;
    font-weight: 500;
  }
  .detail {
    font-size: 0.875rem;
    font-weight: 500;
    p {
      display: flex;
      align-items: center;
      line-height: 1.438rem;
      gap: 10px;
      margin-top: 20px;
    }
  }
`;
const PlanDisplayFeatures = styled(Div)``

const FormContainer = styled(Div)`
	height: 100%;
	display: flex;
	flex-flow: column nowrap;
	justify-content: space-between;
	form { padding: 40px; }
`;



const FormTitle = styled(H5)`color:#fff;font-size:1.2rem;margin:0 0 10px;`
const CheckoutLabel = styled(Label)`color: ${props=>props.theme.label}; font-weight:600; margin-bottom: .375rem !important;display:block;`
const CheckoutInput = styled(Input)`
  width: 100%;
  border: none;
  outline: none;
  color: ${(props) => props.theme.secondary};
  -webkit-text-fill-color: ${(props) => props.theme.secondary};
  padding-left: 0 !important;
  padding-top: 0 !important;
  border-bottom: 1px solid ${(props) => props.theme.label};
  border-radius: 0 !important;
  font-weight: 500;
  font-size: 1rem;
  &:focus {
    box-shadow: none;
  }
  background: transparent !important;
  ::placeholder {
    color: #fff;
  }
  ::-ms-input-placeholder {
    color: #fff;
  }
  -webkit-autofill {
    -webkit-box-shadow: 0 0 0px 1000px ${(props) => props.theme.secondary} inset;
    transition: background-color 5000s ease-in-out 0s;
  }
`;
const SubmitBtn = styled(Button)`
  border: 1px solid transparent;
  background-color: #4893F7;
  color: #fff;
  padding: 8px 20px;
  min-width: 150px;
  border-radius: 6px;
  font-size: 1rem;
  font-weight: 600;
  cursor: pointer;
  &:focus {
    border: thin solid ${(props) => props.theme.primary};
    background-color: ${(props) => props.theme.primary};
    color: #fff;
  }
  &:hover,
  &:focus-visible {
    outline: none;
    box-shadow: 0 0 0 1px ${(props) => props.theme.primary};
  }
  &:disabled,
  &.disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }
`;




export {
	Divider, 
	Foot,
	CheckoutLabel, 
	CheckoutInput, 
	SubmitBtn, 
	ActiveLogo, 
	PrimaryLogoContainer, 
	CheckoutWrapper, 
	CheckoutContainer, 
	OptionsContainer, 
	PaymentWrapper, 
	FormGroup50, 
	FormGroup100,
	FormTitle,
	FormGroup75,
	PaymentContainer,
	QuantityContainer,
	QuantitySelector,
	QuantitySelectorContainer,
	SelectedPlanContainer,
	SelectedPlanItem,
	PlansDisplayContainer,
	PlanDisplay,
	PlanDisplayHeader,
	PlanDisplayPricing,
	PlanDisplayFeatures,
	FormContainer,
	LogoContainer,
}