import React, { useContext, useEffect } from "react";
import { ThemeProvider } from "styled-components";
import { Outlet } from "react-router-dom";
import CurrentSystemCtx from "../context/CurrentSystemCtx";
import { system as systemConst } from "../constants";
import { CheckoutContainer, CheckoutWrapper } from "../styles/Checkout/main";
import "../styles/Checkout/main.scss";

const Layout = (props) => {
  const { children, system } = props;
  const { currentSystem, setCurrentSystem } = useContext(CurrentSystemCtx);

  const _getSystem = () => {
    if (system) {
      return system;
    }
    const localIdentifier = localStorage.getItem("identifier");
    return systemConst[localIdentifier] || systemConst.zs;
  };

  useEffect(() => {
    const currSystem = _getSystem();
    setCurrentSystem(currSystem);
    localStorage.setItem("identifier", currSystem.identifier);
  }, []); // eslint-disable-line

  if (!currentSystem) {
    return null;
  }

  return (
    <ThemeProvider theme={currentSystem?.theme}>
      <main>
        <CheckoutContainer>
          <CheckoutWrapper>
            <Outlet />
            {children}
          </CheckoutWrapper>
        </CheckoutContainer>
      </main>
    </ThemeProvider>
  );
};

export default Layout;
