export const LikeIcon = (props) => {
  const svgStyles = {
    width: props.width,
    height: props.height,
    fill: props.fill || "currentColor",
  };
  return (
    <svg
      width='12'
      height='12'
      viewBox='0 0 12 12'
      style={svgStyles}
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M5.52539 0L4.99336 2.3387L2.82422 4.17549C2.55551 4.40303 2.4 4.73788 2.4 5.08921V10.802C2.4 11.4567 2.94416 12 3.6 12H8.78086C9.23306 12 9.64872 11.7435 9.85195 11.3413L11.9016 7.284C11.9678 7.15541 12 7.0134 12 6.87453V5.41094C12 4.75619 11.4558 4.21293 10.8 4.21293H8.06133C8.23051 3.68763 8.4 3.00686 8.4 2.33402C8.4 1.29432 7.74835 0.61282 7.16719 0.324071C6.58602 0.0353231 6.02461 0.0198889 6.02461 0.0198889L5.52539 0ZM6.44883 1.34659C6.53328 1.37198 6.54356 1.35255 6.63281 1.3969C6.95164 1.55531 7.2 1.73124 7.2 2.33402C7.2 3.16573 6.64688 4.57912 6.64688 4.57912L6.29531 5.41094H10.8V6.80316L8.78086 10.802H3.6V5.08921L6.075 2.99269L6.44883 1.34659ZM0 4.21293V12H1.2V4.21293H0Z'
        fill={svgStyles.fill}
      />
    </svg>
  );
};
