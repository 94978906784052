export const InvisibleIcon = (props) => {
  const svgStyles = {
    width: props.width,
    height: props.height,
    marginRight: props.mr,
    fill: props.fill || "currentColor",
  };

  return (
    <svg
      style={svgStyles}
      viewBox="0 0 20 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M2.91548 0L1.62997 1.28551L3.70561 3.36115C1.09031 5.49567 0 8.37003 0 8.37003C0 8.37003 2.72727 15.6428 10 15.6428C11.8869 15.6428 13.46 15.1471 14.7656 14.4212L17.0845 16.7401L18.37 15.4545L2.91548 0ZM10 1.0973C8.89818 1.0973 7.91436 1.27886 7.01527 1.5625L9.34304 3.89027C9.55849 3.85845 9.77545 3.82457 10 3.82457C12.51 3.82457 14.5455 5.86003 14.5455 8.37003C14.5455 8.59457 14.5116 8.81153 14.4798 9.02699L17.5977 12.1449C19.2767 10.2812 20 8.37003 20 8.37003C20 8.37003 17.2727 1.0973 10 1.0973ZM6.21271 5.86825L7.54261 7.19815C7.37105 7.5526 7.27273 7.94873 7.27273 8.37003C7.27273 9.87639 8.49364 11.0973 10 11.0973C10.4213 11.0973 10.8174 10.999 11.1719 10.8274L12.5018 12.1573C11.7837 12.6334 10.9266 12.9155 10 12.9155C7.49 12.9155 5.45455 10.88 5.45455 8.37003C5.45455 7.44342 5.73658 6.58636 6.21271 5.86825Z"
        fill={svgStyles.fill}
      />
    </svg>
  );
};
