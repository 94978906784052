import React, { useState, useEffect, useContext } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronUp, faChevronDown } from "@fortawesome/pro-solid-svg-icons";
import {
  LogoContainer,
  OptionsContainer,
  PlanDisplay,
  PlanDisplayHeader,
  PlanDisplayPricing,
  PlansDisplayContainer,
  PrimaryLogoContainer,
} from "../../styles/Checkout/main";
import { ActiveLogo } from "../../styles/Checkout/main";
import CurrentSystemCtx from "../../context/CurrentSystemCtx";
import SelectedProductCtx from "../../context/SelectedProductCtx";
import AllProductsCtx from "../../context/AllProductsCtx";
import PrimaryLogo from "../PrimaryLogo";
import CheckCircle from "../svg/CheckCircle";
import { planDetail } from "../../constants";

const OptionsSide = () => {
  const [expandedPlan, setExpandedPlan] = useState({});
  const { currentSystem } = useContext(CurrentSystemCtx);
  const { selectedProduct, setSelectedProduct } =
    useContext(SelectedProductCtx);
  const { products } = useContext(AllProductsCtx);

  const handle_product_selection = (evt) => {
    const selected_uuid = evt.currentTarget.getAttribute("uuid");
    let new_product = products.filter((product) => {
      return product.uuid === selected_uuid;
    });
    if (new_product[0]) {
      const product = new_product[0];
      setSelectedProduct(product);
      document.getElementById(`toggle${product.plan}`)?.click();
    }
  };

  useEffect(() => {
    setExpandedPlan(selectedProduct?.uuid);
  }, []); // eslint-disable-line

  return (
    <OptionsContainer>
      <LogoContainer className='small'>
        <ActiveLogo src={currentSystem.logo} alt={currentSystem.name} />
      </LogoContainer>
      <PlansDisplayContainer>
        {products &&
          products.map((product, idx) => {
            const isSelected = selectedProduct.uuid === product.uuid;
            const isExpanded = expandedPlan === product.uuid;
            const productDetail = planDetail[product.plan];
            return (
              product.listable && (
                <PlanDisplay key={idx} id={product.plan}>
                  <PlanDisplayHeader isselected={isSelected.toString()}>
                  <label>
                  <input
                    style={{ opacity: 0, position: 'absolute', width: '1px', height: '1px', margin: '-1px', padding: '0', overflow: 'hidden' }}
                    type='radio'
                    name='product-selector'
                    onChange={handle_product_selection}
                    uuid={product.uuid}
                    checked={isSelected}
                  />{" "}
                  {isSelected ? "Plan Details" : "Change to"}
                </label>

                    <a
                      style={{ color: "inherit" }}
                      id={`toggle${product.plan}`}
                      href={`${window.location.origin}${window.location.pathname}${window.location.search}#${product.plan}`}
                      onClick={() => setExpandedPlan(product.uuid)}
                    >
                      {/* <FontAwesomeIcon
                        icon={isExpanded ? faChevronUp : faChevronDown}
                        className='v-align-middle'
                      /> */}
                    </a>
                  </PlanDisplayHeader>
                  <PlanDisplayPricing>
                    <div className='name'>{product.name}</div>
                    <div className='price'>
                      {product.price ? (
                        <>
                          ${product.price.toLocaleString()}&nbsp;
                          <span className='recurrence'>
                            /{product.frequency.slice(0, -2)}
                          </span>
                        </>
                      ) : (
                        "Free"
                      )}
                    </div>
                    {isExpanded && (
                      <div className='detail'>
                        <hr />
                        {/* <p>{productDetail?.overview}</p> */}
                        {productDetail?.benefits &&
                          productDetail.benefits.map((benefit, index) => (
                            <p key={index}>
                              <CheckCircle width={20} height={20} />
                              {benefit}
                            </p>
                          ))}
                      </div>
                    )}
                  </PlanDisplayPricing>
                </PlanDisplay>
              )
            );
          })}
          <div style={{ fontSize: '0.8em', fontStyle: 'italic' }}> 
          * <a href="https://www.zealstream.com/tos2" target="_new">restrictions apply</a>
          </div>
      </PlansDisplayContainer>
    </OptionsContainer>
  );
};

export default OptionsSide;
