export const VisibleIcon = (props) => {
  const svgStyles = {
    width: props.width,
    height: props.height,
    marginRight: props.mr,
    fill: props.fill || "currentColor",
  };

  return (
    <svg
      width='20'
      height='14'
      viewBox='0 0 20 14'
      style={svgStyles}
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M10 0.75C5.83333 0.75 2.27499 3.34167 0.833328 7C2.27499 10.6583 5.83333 13.25 10 13.25C14.1667 13.25 17.725 10.6583 19.1667 7C17.725 3.34167 14.1667 0.75 10 0.75ZM10 11.1667C7.7 11.1667 5.83333 9.3 5.83333 7C5.83333 4.7 7.7 2.83333 10 2.83333C12.3 2.83333 14.1667 4.7 14.1667 7C14.1667 9.3 12.3 11.1667 10 11.1667ZM10 4.5C8.61666 4.5 7.5 5.61667 7.5 7C7.5 8.38333 8.61666 9.5 10 9.5C11.3833 9.5 12.5 8.38333 12.5 7C12.5 5.61667 11.3833 4.5 10 4.5Z'
        fill={svgStyles.fill}
      />
    </svg>
  );
};

export default VisibleIcon;
