import React, { useContext, useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import SelectedProductCtx from "../context/SelectedProductCtx";
import CurrentSystemCtx from "../context/CurrentSystemCtx";
import AllProductCtx from "../context/AllProductsCtx";
import PaymentSide from "../components/Checkout/PaymentSide";
import OptionsSide from "../components/Checkout/OptionsSide";
import productHelpers from "../helpers/products";
import stripeHelpers from "../helpers/stripe";

const LaunchCheckout = () => {
  const { currentSystem, setCurrentSystem } = useContext(CurrentSystemCtx);
  const { selectedProduct, setSelectedProduct } = useContext(SelectedProductCtx);
  const { products, setProducts } = useContext(AllProductCtx);
  const [mount, setMount] = useState(false);
  const [searchParams] = useSearchParams();
  const plan = searchParams.get("plan");

  const get_current_system = async () => {
    try {
      const systemProducts = await productHelpers.get_products(
        currentSystem.identifier
      );
      let selectedProductIndex = systemProducts.findIndex(
        (product) => product.is_default
      );

      if (plan) {
        selectedProductIndex = systemProducts.findIndex(
          (product) => product.plan === plan
        );
      }

      const selectedProduct = { ...systemProducts[selectedProductIndex] };
      systemProducts.splice(0, 0, selectedProduct);
      systemProducts.splice(selectedProductIndex + 1, 1);
      setProducts(systemProducts);
      setSelectedProduct(selectedProduct);
      /*
			For now, to simpify things, we are not using payment intent API we will used legacy Token API (Stripe).  Later we will revisit payent intent
			Subscription set up plus charge for funnels puls needing webhooks make this more than we need to get this off the ground
		*/
      setMount(true);
    } catch (err) {
      console.log(err);
    }
  };

  const handleMyLoad = async () => await get_current_system();

  useEffect(() => {
    handleMyLoad();
  }, []);

  if (!mount || !selectedProduct) {
    return null;
  }

  return (
    <>
      <OptionsSide />
      <PaymentSide />
    </>
  );
};
export default LaunchCheckout;
