export const EnterIcon = (props) => {
  const svgStyles = {
    width: props.width,
    height: props.height,
    fill: props.fill || "currentColor",
  };
  return (
    <svg
      width="12"
      height="8"
      viewBox="0 0 12 8"
      style={svgStyles}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12 0H10.8V4.8H2.28L3.24 3.84L2.4 3L0 5.4L2.4 7.8L3.24 6.96L2.28 6H12V0Z"
        fill={svgStyles.fill}
      />
    </svg>
  );
};
