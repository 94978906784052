import React, { useContext } from "react";
import { REACT_APP_AUTH_SERVER_URL } from "../env";
import CurrentSystemCtx from "../context/CurrentSystemCtx";
import {
  LogoContainer,
  ActiveLogo,
  OptionsContainer,
  PlanDisplayPricing,
  PrimaryLogoContainer,
  SubmitBtn,
  Foot,
} from "../styles/Checkout/main";
import PrimaryLogo from "../components/PrimaryLogo";

const AllSet = () => {
  const { currentSystem } = useContext(CurrentSystemCtx);

  return (
    <OptionsContainer style={{ borderRadius: 6, minHeight: 660 }}>
      <LogoContainer className='small pt-5'>
        <ActiveLogo src={currentSystem.logo} alt={currentSystem.name} />
        {currentSystem.powered && (
          <>
            {/* powered by&nbsp;
            <PrimaryLogoContainer>
              <PrimaryLogo />
            </PrimaryLogoContainer> */}
          </>
        )}
      </LogoContainer>
      <PlanDisplayPricing
        className='text-center py-0 m-auto'
        style={{
          fontWeight: 500,
          paddingLeft: 40,
          paddingRight: 40,
          maxWidth: 520,
        }}
      >
        <h2>You are all set!</h2>
        <p>
          We are finalizing your account setup. You will receive one more email
          shortly to let you know when your account ready for you to login.
        </p>
        <a
          href={`${REACT_APP_AUTH_SERVER_URL}/?system=${currentSystem.identifier}`}
        >
          <SubmitBtn
            type='button'
            style={{ marginTop: "12%", marginBottom: "30%" }}
          >
            Go to Login
          </SubmitBtn>
        </a>
        <Foot>
          <small className='small'>
            &copy; Zeal Stream, 2024 &amp; beyond. All Rights Reserved.
          </small>
        </Foot>
      </PlanDisplayPricing>
    </OptionsContainer>
  );
};

export default AllSet;
