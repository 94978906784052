import React, { useContext, useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import axios from "axios";
// import { useTheme } from "styled-components";
import { toast } from "react-toastify";
import {
  REACT_APP_API_ZEALINNOVATIONS,
  REACT_APP_AUTH_SERVER_URL,
} from "../env";
import CurrentSystemCtx from "../context/CurrentSystemCtx";
import {
  LogoContainer,
  ActiveLogo,
  OptionsContainer,
  PlanDisplayPricing,
  PrimaryLogoContainer,
  SubmitBtn,
  FormGroup100,
  CheckoutLabel,
  CheckoutInput,
} from "../styles/Checkout/main";
import PrimaryLogo from "../components/PrimaryLogo";
import { inviteConfirmationType } from "../constants";
import { regexPattern } from "../constants";
import { EnterIcon, ArrowRightIcon, LikeIcon } from "../components/svg";
import SecureInput from "../components/SecureInput";
import restServiceHelper from "../helpers/restServiceHelper";

const FORM_TYPE = {
  INFORMATION_SET: 0,
  ACCEPT_INVITATION: 1,
  ALL_SET: 2,
  ACCEPTED: 3
};
const Invitation = (props) => {
  const navigator = useNavigate();
  // const theme = useTheme();
  const { currentSystem } = useContext(CurrentSystemCtx);
  const theme = currentSystem.theme;
  const [searchParams] = useSearchParams();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [formType, setFormType] = useState();
  const [errorMessage, setErrorMessage] = useState();
  const invitation_id = searchParams.get("invitation_code");

  const _checkInvitation = async () => {
    try {
      const res = await axios.post(
        `${REACT_APP_API_ZEALINNOVATIONS}/users/check-invitation`,
        { invitation_id }
      );
      const { data } = res;
      if (!data?.success) {
        return;
      }
      if (data?.record?.existing_user) {
        setFormType(FORM_TYPE.ACCEPT_INVITATION);
      } else {
        setFormType(FORM_TYPE.INFORMATION_SET);
      }
    } catch (err) {
      const message = restServiceHelper.handleError(err);
      setErrorMessage(message);
      toast.error(message);
    }
  };

  useEffect(() => {
    if (invitation_id) {
      _checkInvitation();
    } else {
      navigator(-1);
    }
  }, []); // eslint-disable-line

  const _submitUserInfo = async (type, event) => {
    try {
      const userData = {
        invitation_id,
        type,
      };
      // get form data
      if (type === inviteConfirmationType.REGISTER && event) {
        event.preventDefault();
        const formData = new FormData(event.target);
        for (let [name, value] of formData) {
          userData[name] = value;
        }
        const { password, confirm_password } = userData;
        if (password !== confirm_password) {
          toast.error("Password does not match!");
          return;
        }
        if (!regexPattern.USER_PASS.regex.test(password)) {
          toast.error("Password is invalid!");
          return;
        }
      }

      setIsSubmitting(true);
      const res = await axios.post(
        `${REACT_APP_API_ZEALINNOVATIONS}/users/invite-confirmation`,
        userData
      );
      if (res?.data?.success && type === inviteConfirmationType.REGISTER) {
        setFormType(FORM_TYPE.ALL_SET);
      }

      if (res?.data?.success && type === inviteConfirmationType.ACCEPT) {
        setFormType(FORM_TYPE.ACCEPTED);
      }
    } catch (err) {
      toast.error(restServiceHelper.handleError(err));
    } finally {
      setIsSubmitting(false);
    }
  };

  const _renderForm = () => {
    if (formType === FORM_TYPE.INFORMATION_SET) {
      return (
        <form
          id='set-password'
          className='text-left no-select'
          autoComplete='off'
          onSubmit={(e) => _submitUserInfo(inviteConfirmationType.REGISTER, e)}
        >
          <h2 className='text-center mb-0'>Invite Confirmed</h2>
          <p className='text-center mb-4'>
            Please enter and confirm your information for your account.
            <CheckoutLabel
              className='mt-3'
              style={{ fontWeight: "initial", fontSize: "small" }}
            >
              {regexPattern.USER_PASS.message}
            </CheckoutLabel>
          </p>
          <FormGroup100>
            <CheckoutLabel
              htmlFor='firstName'
              className='form-label text-nowrap'
            >
              First Name
            </CheckoutLabel>
            <CheckoutInput
              type='text'
              className='form-control'
              id='firstName'
              name='first_name'
              placeholder=''
              required
              disabled={isSubmitting}
            />
          </FormGroup100>
          <FormGroup100>
            <CheckoutLabel
              htmlFor='lastName'
              className='form-label text-nowrap'
            >
              Last Name
            </CheckoutLabel>
            <CheckoutInput
              type='text'
              className='form-control'
              id='lastName'
              name='last_name'
              placeholder=''
              required
              disabled={isSubmitting}
            />
          </FormGroup100>
          <SecureInput
            theme={theme}
            label='Password'
            inputProps={{
              className: "form-control",
              id: "password",
              name: "password",
              placeholder: "",
              required: true,
              disabled: isSubmitting,
            }}
          />
          <SecureInput
            theme={theme}
            label='Confirm Password'
            inputProps={{
              className: "form-control",
              id: "confirmPassword",
              name: "confirm_password",
              placeholder: "",
              required: true,
              disabled: isSubmitting,
            }}
          />
          <FormGroup100 className='text-center pt-3 pb-3'>
            <SubmitBtn
              form='set-password'
              id='set-password-btn'
              type='submit'
              disabled={isSubmitting}
              style={{
                minWidth: 90,
                padding: "6px 10px",
                fontSize: "0.875rem",
              }}
            >
              {isSubmitting ? (
                <div className='spinner-border' role='status'></div>
              ) : (
                <>
                  <EnterIcon /> {"Submit"}
                </>
              )}
            </SubmitBtn>
          </FormGroup100>
        </form>
      );
    }

    if (formType === FORM_TYPE.ACCEPT_INVITATION) {
      return (
        <div style={{ marginBottom: 60 }}>
          <h2 className='text-center mb-0'>You've been invited.</h2>
          <p className='text-center' style={{ marginBottom: 60 }}>
            You have been invited to the Zeal Stream account of Revealed Films
            as a Member. Click 'Accept' below to link your current Zeal
            Innovations login to your new Revealed Films account.
          </p>
          <SubmitBtn
            type='button'
            style={{
              minWidth: 90,
              padding: "6px 10px",
              fontSize: "0.875rem",
            }}
            onClick={() => _submitUserInfo(inviteConfirmationType.ACCEPT)}
          >
            <LikeIcon />
            {" Accept"}
          </SubmitBtn>
        </div>
      );
    }

    if (formType === FORM_TYPE.ALL_SET) {
      return (
        <div>
          <h2 className='text-center mb-0'>You are all set!</h2>
          <p className='text-center' style={{ marginBottom: 60 }}>
            You can now login with the new credentials you just created.
          </p>
          <a
            href={`${REACT_APP_AUTH_SERVER_URL}/?system=${currentSystem.identifier}`}
          >
            <SubmitBtn
              type='button'
              style={{
                minWidth: 90,
                padding: "6px 10px",
                fontSize: "0.875rem",
              }}
            >
              {"Login "}
              <ArrowRightIcon />
            </SubmitBtn>
          </a>
        </div>
      );
    }

    if (formType === FORM_TYPE.ACCEPTED) {
      return (
        <div>
          <h2 className='text-center mb-0'>You are all set!</h2>
          <p className='text-center' style={{ marginBottom: 60 }}>
            You can now login with your existing credentials.
          </p>
          <a
            href={`${REACT_APP_AUTH_SERVER_URL}/?system=${currentSystem.identifier}`}
          >
            <SubmitBtn
              type='button'
              style={{
                minWidth: 90,
                padding: "6px 10px",
                fontSize: "0.875rem",
              }}
            >
              {"Login "}
              <ArrowRightIcon />
            </SubmitBtn>
          </a>
        </div>
      );
    }

    if (errorMessage) {
      return <span style={{ color: theme.danger }}>{errorMessage}</span>;
    }

    return <div className='spinner-border mx-auto' role='status'></div>;
  };

  if (!invitation_id) {
    return null;
  }

  return (
    <OptionsContainer style={{ borderRadius: 6 }}>
      <LogoContainer className='small pt-5'>
        <ActiveLogo src={currentSystem.logo} alt={currentSystem.name} />
        {currentSystem.powered && (
          <>
            {/* powered by&nbsp;
            <PrimaryLogoContainer>
              <PrimaryLogo />
            </PrimaryLogoContainer> */}
          </>
        )}
      </LogoContainer>
      <PlanDisplayPricing
        className='text-center m-auto pt-0'
        style={{
          fontWeight: 500,
          padding: 40,
          paddingTop: 0,
          minHeight: 267,
          maxWidth: 506,
          display: "flex",
          flexFlow: "column",
          justifyContent: "space-between",
        }}
      >
        {_renderForm()}
        <div>
          <small className='small'>
            &copy; Zeal Stream, 2024 &amp; beyond. All Rights Reserved.
          </small>
        </div>
      </PlanDisplayPricing>
    </OptionsContainer>
  );
};

export default Invitation;
