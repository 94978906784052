import React, { useState } from "react";
import PropTypes from "prop-types";
import {
  FormGroup100,
  CheckoutLabel,
  CheckoutInput,
} from "../../styles/Checkout/main";
import { InvisibleIcon, VisibleIcon } from "../svg";

const SecureInput = (props) => {
  const { theme, label, inputProps } = props;
  const [isVisible, setIsVisible] = useState(false);

  return (
    <FormGroup100 style={{ position: "relative" }}>
      {label && (
        <CheckoutLabel htmlFor='password' className='form-label text-nowrap'>
          {label}
        </CheckoutLabel>
      )}
      <CheckoutInput
        type={isVisible ? "text" : "password"}
        className='form-control'
        {...inputProps}
      />
      <span
        style={{ position: "absolute", right: 0, bottom: 10 }}
        onClick={() => setIsVisible((state) => !state)}
      >
        {isVisible ? (
          <VisibleIcon width={20} height={16} fill={theme.dkgrey} />
        ) : (
          <InvisibleIcon width={20} height={16} fill={theme.dkgrey} />
        )}
      </span>
    </FormGroup100>
  );
};
SecureInput.propTypes = {
  theme: PropTypes.objectOf(PropTypes.any).isRequired,
  label: PropTypes.string,
  inputProps: PropTypes.objectOf(PropTypes.any).isRequired,
};
SecureInput.defaultProps = {
  label: undefined,
};
export default SecureInput;
