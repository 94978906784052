import React from 'react';

const CheckCircle = (props) => {
  const svgStyles = {
    width: props.width,
    height: props.height,
    marginRight: props.mr,
    fill: props.fill || "#4893F7",
  };
  return (
    <svg
      style={svgStyles}
      viewBox='0 0 20 20'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M10 0C4.47715 0 0 4.47715 0 10C0 15.5228 4.47715 20 10 20C15.5228 20 20 15.5228 20 10C19.9936 4.47982 15.5202 0.00642897 10 0Z'
        fill={svgStyles.fill}
      />
      <path
        d='M14.5678 7.15086L9.73029 13.7156C9.61492 13.8686 9.44289 13.9689 9.25285 13.9939C9.0628 14.0189 8.87069 13.9665 8.71968 13.8484L5.26525 11.0866C4.96042 10.8426 4.91106 10.3978 5.155 10.0929C5.39895 9.78809 5.84382 9.73873 6.14865 9.98268L9.02922 12.2873L13.4299 6.31482C13.5743 6.09827 13.8259 5.97855 14.0849 6.00318C14.344 6.02782 14.5686 6.19282 14.6695 6.43268C14.7704 6.67254 14.7313 6.94845 14.5678 7.15086Z'
        fill='white'
      />
    </svg>
  );
}

export default CheckCircle;
