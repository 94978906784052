import { BrowserRouter as Router, Routes, Route, Navigate } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import { system } from "./constants";
import "./styles/customToast.scss";
import Layout from "./layouts/Checkout";
import Checkout from "./pages/Checkout";
import ThankYou from "./pages/ThankYou";
import Verification from "./pages/Verification";
import AllSet from "./pages/AllSet";
import Invitation from "./pages/Invitation";
import CurrentSystemCtx from "./context/CurrentSystemCtx";
import { useContext, useEffect } from "react";

function App() {
  
  const { currentSystem } = useContext(CurrentSystemCtx);

  useEffect(() => {
    if (currentSystem) {
      document.title = `Registration ${currentSystem.name}`;
      const link = document.querySelector("link[rel~='icon']");
      if (link) {
        link.href = currentSystem.icon;
      }
    }
  }, [currentSystem]);

  return (
    <>
      <Router>
        <Routes>
          <Route path="/" element={<Navigate to="/stream" replace />} />
          <Route path='/stream' element={<Layout system={system.zs} />}>
            <Route index element={<Checkout />} />
          </Route>
          <Route path='/success' element={<ThankYou />} />
          <Route path='/verify' element={<Verification />} />
          <Route path='/allset' element={<AllSet />} />
          <Route path='/invite' element={<Invitation />} />
        </Routes>
      </Router>
      <ToastContainer
        position='top-center'
        autoClose={3000}
        icon={false}
        hideProgressBar
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss={false}
        draggable={false}
        pauseOnHover
      />
    </>
  );
}

export default App;

// http://localhost:3000/success?verification_id=5e03bc49-91c8-401f-bf85-860c445c2c8e&plan_purchased={"updated_at":1713643645,"price_stripe":7900,"listable":true,"frequency":"Monthly","is_default":true,"uuid":"5bb8098f-7a78-4420-96e4-6366cfa99d83","offer_id":"1","system_id":"zs","price":79,"name":"Zeal%20Stream%20Pro","plan":"PRO"}