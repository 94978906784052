export const system = {
  zs: {
    logo: "/logos/stream.svg",
    icon: "/streamico.png",
    name: "Zeal Stream",
    powered: true,
    identifier: "zs",
    theme: {
      primary: "rgb(72, 147, 247)",
      secondary: "rgb(37,71,120)",
      danger: "#EA1A4C",
      label: "#BECAD8",
      light: "#E0E0E0",
      dkgrey: "#A9B3BF",
      contrastText: "#FFFFFF",
    },
  },
  zl: {
    logo: "/logos/launch.svg",
    icon: "/launchico.png",
    name: "Zeal Launch",
    powered: true,
    identifier: "zl",
    theme: {
      primary: "rgb(227,123,0)",
      secondary: "rgb(184,57,14)",
      danger: "#EA1A4C",
      label: "#BECAD8",
      light: "#E0E0E0",
      dkgrey: "#A9B3BF",
      contrastText: "#FFFFFF",
    },
  },
  ze: {
    logo: "/logos/ecom.svg",
    icon: "/ecomico.png",
    name: "Zeal Ecom",
    powered: true,
    identifier: "ze",
    theme: {
      primary: "rgb(123,171,69)",
      secondary: "rgb(64,84,39)",
      danger: "#EA1A4C",
      label: "#BECAD8",
      light: "#E0E0E0",
      dkgrey: "#A9B3BF",
      contrastText: "#FFFFFF",
    },
  },
};

export const planDetail = {
  PRO: {
    overview:
      "Keeping it simple, we got one plan.  30 day money back guarantee!",
    benefits: [
      "Fastest player on the market",
      "Captions in different languages included",
      "Videos play in less than 10 minutes",
      "No-Code Video Swapping",
      "Virtually unlimited video uploads",
      "3TB of FREE Bandwidth Every Month",
      "Extra Bandwidth as low as $.025 per GB"
    ],
  }
};

export const errorCode = {
  CODE_EXPIRED: "1001",
  CODE_WRONG: "1002",
};

export const regexPattern = {
  USER_PASS: {
    regex:
      /^(?=.*[0-9])(?=.*[a-zA-Z])(?=.*[!@#$%^&*()\\-`.+,/|])[a-zA-Z0-9!@#$%^&*()\\-`.+,/|]{6,128}$/,
    message:
      "Password includes number, letter, special characters, and 6 character minimum.",
  },
};

export const inviteConfirmationType = {
  REGISTER: "REGISTER",
  ACCEPT: "ACCEPT",
};
