import React, { useState } from 'react'
import { system as systemConst } from '../constants';  // Importing the system constants

const CurrentSystemCtx = React.createContext()

export const CurrentSystemProvider = ({ children }) => {

    const [currentSystem, setCurrentSystem] = useState(systemConst.zs);

    return (
        <CurrentSystemCtx.Provider value={{ currentSystem, setCurrentSystem }}>
            {children}
        </CurrentSystemCtx.Provider>
    )

}

export default CurrentSystemCtx