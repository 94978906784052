import React, { useContext, useEffect, useState } from "react";
import { Link, useSearchParams } from "react-router-dom";
import { toast } from "react-toastify";
import axios from "axios";
import { REACT_APP_API_ZEALINNOVATIONS } from "../env";
import {
  LogoContainer,
  ActiveLogo,
  OptionsContainer,
  PlanDisplayPricing,
} from "../styles/Checkout/main";
import CurrentSystemCtx from "../context/CurrentSystemCtx";
import SelectedProductCtx from "../context/SelectedProductCtx";

const ThankYou = () => {
  
  const { currentSystem } = useContext(CurrentSystemCtx);
  const { selectedProduct, setSelectedProduct } = useContext(SelectedProductCtx);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [searchParams] = useSearchParams();
  const verification_id = searchParams.get("verification_id");
  const purchasedPlan = searchParams.get("plan_purchased");

  const setPlanToDisplay = () => {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const purchasedPlanParam = urlParams.get("plan_purchased");
  };

  useEffect(() => setPlanToDisplay(), []);

  const _resendCode = async () => {
    try {
      setIsSubmitting(true);
      const res = await axios.post(
        `${REACT_APP_API_ZEALINNOVATIONS}/account/resend_verification_code`,
        { verification_id }
      );
      if (res?.data?.success) {
        toast.success("Code has been resent!");
      }
    } catch (err) {
      console.log(err);
      if (err?.response?.data?.message?.message) {
        toast.error(err?.response?.data?.message?.message);
      }
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <>
      <OptionsContainer>
        <LogoContainer className='small'>
          <ActiveLogo src={currentSystem.logo} alt={currentSystem.name} />
        </LogoContainer>
        <PlanDisplayPricing style={{ padding: "7px 38px 38px" }}>
          <h2 className='text-center price'>Thank You!</h2>
          <p className='text-center mb-0' style={{ fontWeight: 500 }}>
            Your purchase has been completed successfully. 
            <p className='text-center mb-0' style={{ fontWeight: 500 }}>
            In a minute, you'll receive an e-mail asking you to verify your e-mail address
            and setup a password.  
            </p>
          </p>
        </PlanDisplayPricing>
      </OptionsContainer>
    </>
  );
};

export default ThankYou;
